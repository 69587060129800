import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'weekDay',
})
export class WeekDayPipe implements PipeTransform {
  transform(value: number, format: 'short' | 'long' | '3' = 'short'): string {
    if (value && value > 1 && value < 7) {
      return [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Samstag',
        'Sonntag',
      ][value - 1].substring(
        0,
        format === 'short' ? 2 : format === '3' ? 3 : 100
      );
    }

    return '-';
  }
}
